const mappingFromCookie = {
    func: 'functional',
    anl: 'analytics',
    adv: 'advertising',
    dt3: 'dataToThirdParty',
    ess: 'essential'
}

function toHeaderFormat(policy) {
    const raw = {}
    for (const [cookieKey, policyField] of Object.entries(mappingFromCookie)) {
        raw[cookieKey] = policy[policyField] ? 1 : 0
    }

    return encodeURIComponent(JSON.stringify(raw))
}

function initConsent(expOpen, baseUrl, consentPolicy, setConsentReady, setNativeConsentPolicyDetails, setNativeConsentPolicyHeaderObject) {
    if (!expOpen) {
        setConsentReady()
        return
    }
    if (typeof window === 'undefined') { // SSR - no native API
        setConsentReady()
        return
    }

    if (window.consentPolicyManager) { // Native API loaded
        initManager()
    }
    setConsentReady()

    function updateModelFromNative(policyDetails) {
        setNativeConsentPolicyDetails(policyDetails || window.consentPolicyManager.getCurrentConsentPolicy())
        setNativeConsentPolicyHeaderObject(window.consentPolicyManager._getConsentPolicyHeader())
    }

    function initManager() {
        window.consentPolicyManager.init({
            consentPolicy,
            baseUrl
        })
        updateModelFromNative()
        window.document.addEventListener('consentPolicyChanged', event => {
            const policyDetails = event.detail
            updateModelFromNative(policyDetails)
        })
    }
}

module.exports = {
    initConsent,
    toHeaderFormat
}
